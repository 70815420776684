<template>
  <div
    class="flex flex-col overflow-hidden max-w-screen-sm mx-auto w-full h-app max-h-app"
  >
    <!-- header -->
    <TitleBarCard :title="item.name" @close="close" :sharable="true" @share="share" />

    <!-- 產品明細 -->
    <div
      class="flex-grow overflow-y-scroll scrollbar-hide bg-white scroll-smooth"
      aria-view="body"
    >
      <div class="min-h-min">
        <!-- 產品圖片及文字 -->
        <div
          class="flex items-stretch space-x-4 bg-white p-4 shadow shadow-slate-100 sticky top-0 z-10"
        >
          <div class="flex-none">
            <div class="w-32 h-32">
              <img
                :src="item.image || '/imgs/no_photo.jpg'"
                class="object-contain w-full h-full rounded"
              />
            </div>
          </div>

          <div class="flex-grow flex flex-col">
            <div class="flex-grow">
              <h1 class="font-bold mb-1">{{ item.name }}</h1>
              <template v-if="selected_product">
                <div class="text-sm">
                  <p class="line-clamp-1">{{ selected_product.name }}</p>
                  <p class="text-primary font-bold">
                    現金優惠價{{ currency }}{{ selected_product.discount | number_format }}
                  </p>
                  <p>售價{{ currency }}{{ selected_product.price | number_format }}</p>
                  <!-- <p class="text-gray-500 text-xxs">現金優惠價{{ selected_product.discount - selected_product.price | number_format }}</p> -->
                </div>
              </template>
            </div>
            <div class="flex-none flex justify-end">
              <QuantityPanel
                v-if="!stockout"
                :disabled="submitting"
                v-model="form.quantity"
                :onlyonce="forOnce"
              />
            </div>
          </div>
        </div>
        <div class="leading-none tracking-wider">
          <!-- 選擇面額 -->
          <div class="bg-light-gray rounded p-4" v-if="item.products.length">
            <h1 class="text-xl font-bold text-primary mb-4">選擇面值</h1>
            <div>
              <div
                v-for="product in item.products"
                :key="product.no"
                class="text-xs mb-4 text-dove-gray"
                :class="[
                  product.stockout
                    ? 'opacity-50 cursor-not-allowed'
                    : 'bg-white cursor-pointer',
                ]"
                @click="setForm(product)"
              >
                <div
                  class="p-2 border rounded h-full flex items-center space-x-4"
                  :class="[
                    product.no === form.product
                      ? 'bg-primary bg-opacity-20 border-primary text-white'
                      : 'border-dove-gray',
                  ]"
                >
                  <p class="flex-grow">
                    {{ product.name }} <span v-if="product.stockout">售罄</span>
                  </p>
                  <p
                    class="px-2 py-1 rounded flex-none"
                    :class="[
                      product.no === form.product
                        ? 'bg-white text-primary'
                        : 'bg-primary text-white',
                    ]"
                  >
                    <span class="text-xxs">{{ currency }}</span>
                    {{ product.discount || product.price | number_format }}
                    <template
                      v-if="product.discount && !(product.discount == product.price)"
                    >
                      <span>|</span>
                      <del>{{
                        product.price | number_format
                      }}</del>
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 填寫相關資料 -->
          <div
            class="bg-light-gray rounded-md p-4"
            aria-view="options"
            v-if="selected_product && selected_product.fields?.length"
          >
            <h1 class="text-xl font-bold text-primary mb-4">填寫資料</h1>
            <div v-for="option in selected_product.fields" :key="option.name">
              <label
                class="label"
                :for="option.name"
                v-text="option.label_zh != ''? option.label_zh: option.label"
              />
              <OptionInput v-model="form.options[option.name]" :option="option" />
            </div>
          </div>

          <!-- 商品介紹 -->
          <div class="border-t p-4 mb-4 text-dove-gray" v-if="item.description">
            <div class="min-h-[100px] overflow-y-scroll scrollbar-hide">
              <HtmlReader :text="item.description" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部區 -->
    <div class="flex-none bg-white p-4">
      <div>
        <button
          type="button"
          class="btn text-base w-full"
          @click="submit"
          :disabled="stockout || submitting"
        >
          加入購物車
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HtmlReader from "@luminexs/components/form/HTMLReader.vue";
import SpecificationItem from "@/components/order/SpecificationItem";
import QuantityPanel from "@/components/order/QuantityPanel";
import OptionInput from "@/components/product/specification/OptionInput";
import TitleBarCard from "@/components/card/TitleBarCard.vue";
import { mapActions, mapGetters } from "vuex";
import { first } from "lodash";

export default {
  components: {
    SpecificationItem,
    QuantityPanel,
    OptionInput,
    HtmlReader,
    TitleBarCard,
  },

  props: {
    height: {
      type: String,
      default: "h-app",
    },
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {},
      selected_product: null,
      external: 0,
      submitting: false,
      sharedata: {
        title: "",
        text: "",
        url: "",
      },
    };
  },
  methods: {
    ...mapActions({
      fetch: "cart/fetch",
    }),

    close() {
      this.$parent.close();
    },

    async share() {
      this.$share.open({
        title: `${this.info.name} - ${this.item.name}`,
        text: this.item.description,
        url: `${window.location.origin}${this.localePath(`/order/product/${this.item.no}`)}}`,
      });
    },

    setForm(_product) {
      const product = _product || first(this.item.products.filter((p) => !p.stockout));

      if (!product || product.stockout) return;

      this.selected_product = product;

      let form_options = {};
      product.fields.forEach((option) => {
        form_options[option.name] = "";
      });

      this.form = {
        product: product.no,
        options: form_options,
        quantity: 1,
      };
      if (!_product) return;
      this.$nextTick(() => {
        const el = this.$el.querySelector('[aria-view="options"]');
        if (el) {
          this.$el
            .querySelector('[aria-view="body"]')
            .scrollTo({ top: el.offsetTop - 250, behavior: "smooth" });
        }
      });
    },

    async submit() {
      
      if (this.submitting || this.stockout) return;
      if (this.isGuest(`/order/product/${this.item.no}`)) {
        this.close();
        return;
      }
      try {
        this.submitting = true;
        await this.$axios.post("/api/cart", this.form);
        this.fetch();
        this.close();
        this.$loader.success("已加入購物車");
      } catch (e) {
        this.handleErrorsMessage(e);
      } finally {
        this.submitting = false;
      }
    },
  },

  computed: {
    ...mapGetters({
      
      info: "app/info",
      isSafari: "app/isSafari",
    }),
    show() {
      return this.$parent.show;
    },
    price() {
      const { discount, price } = this.selected_product;
      return discount || price;
    },
    total() {
      return this.price * this.form.quantity;
    },
    stockout() {
      if (!this.selected_product) return true;
      return this.selected_product.stockout;
    },
    forOnce() {
      if (!this.selected_product) return true;
      return this.selected_product.once_for_each_only;
    },
  },

  created() {
    this.setForm();
  },
};
</script>

<style lang="css" scoped>
.browser {
  height: 100vh;
}

.safari {
  height: calc(100vh - 75px);
}
</style>
